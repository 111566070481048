import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from "./aboutus/aboutus";
import EventPage from "./events/eventspage.jsx";
import Test from "./test.js";
import SponsorsPage from "./sponsors/SponsorsPage.jsx";
import HomePage from "./homepage/HomePage.jsx";
import TeamMembers from "./teams/teampage.jsx";
import Contact from "./contactus/contactUs";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Test />} />
        <Route path="/events" element={<EventPage />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/teammembers" element={<TeamMembers/>} />
        <Route path="/sponsors" element={<SponsorsPage />}/>
        <Route path="/home" element={<HomePage />}/>
        <Route path="/contactus" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
