import React from "react";
import * as Components from "./CirclesComponent";

const Circles = ({ content, bgColor }) => {
  return (
    <Components.CircleItem style={{ backgroundColor: bgColor }}>
      {content}
    </Components.CircleItem>
  );
};

export default Circles;
