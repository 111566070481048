import React from 'react';
import { Box } from '@mui/material';
import NavBar from '../../components/Navbar/Header';
import EventPageWithEdits from "../../components/eventspage/events";

const EventPage = () => {
	return (
		<Box>
			<NavBar />
			<EventPageWithEdits />
		</Box>
	);
};

export default EventPage;