import React from 'react';
import { IoMdTime } from 'react-icons/io';
import { FaCalendarAlt } from 'react-icons/fa';
import { CiLocationOn } from 'react-icons/ci';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const HorizontalCard = ({ title, description, imageUrl, date, time, location }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const cardStyle = {
    width: '87%',
    height: isSmallScreen ? 'auto' : '320px', // Set height to 'auto' on small screens
    left: '6%',
    backgroundColor: '#F0FFFF',
    borderRadius: '15px',
    border: '2px solid #3E7BDF', // Adjust this value for different screen sizes
    position: 'relative',
    top: isBigScreen? '-150px' : '60px',
    zIndex: '2',
  };
  

  return (
    <Container maxWidth="xl">
      <Card style={cardStyle}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              alt={title}
              style={{ height: '295px', width: '80%', objectFit: 'cover', borderRadius: '4px', margin: '10px' }}
              image={imageUrl}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent style={{ textAlign: 'center', padding: '16px' }}>
              <Typography variant="h5" component="div" style={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
              <br />
              <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                {description}
              </Typography>
              <br />
              <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                <FaCalendarAlt style={{ marginRight: '5px' }} /> {date} &emsp;&emsp;&emsp;<IoMdTime /> {time} &emsp;&emsp;&emsp;<CiLocationOn /> {location}&emsp;&emsp;&emsp;&emsp;&emsp;
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '1em' }}>
                <Button variant="contained" color="primary" style={{ backgroundColor: '#3E7BDF', width: '45%' }}>
                  Register
                </Button>
                <Button variant="contained" color="primary" style={{ backgroundColor: '#3E7BDF', width: '45%' }}>
                  More Details
                </Button>
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default HorizontalCard;
