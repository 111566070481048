import React from 'react';
import { Box } from '@mui/material';
import NavBar from '../../components/Navbar/Header';
import Edits from "../../components/contactuspage/contactus";

const Contact = () => {
	return (
		<Box>
			<NavBar />
			<Edits />
		</Box>
	);
};

export default Contact;