import styled from "styled-components";

export const CardOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 100px;
  @media (max-width: 1169px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 292px;
  height: 125px;
  border-radius: 30px;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 540px) {
    width: 286px;
    height: 119px;
  }
`;

export const SponsorsCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: #d8d8d8;
  box-sizing: border-box;
  border-radius: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 4px solid #676c72;
  flex-direction: column;
  transition: transform 0.9s cubic-bezier(0.5, 1.25, 0.5, 1.1);
`;

export const SponsorImgDiv = styled.div`
  width: 285px;
  height: 117px;
  top: 30px;
  left: 37px;
  border-radius: 30px;
  background-color: #f1f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 540px) {
    width: 279px;
    height: 111px;
  }
`;

export const SponsorImg = styled.img`
  height: 70px;
  width: auto;
  top: 39px;
  left: 69px;
`;

export const SponsorText = styled.div`
  color: black;
  font-size: 28px;
  font-family: "Inter", sans-serif;
  font-weight: 550;
  word-wrap: break-word;
  @media (max-width: 540px) {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 26px;
  }
`;

export const SponsorUs = styled.button`
  cursor: pointer;

  border-radius: 9.08px;
  padding: 28px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 36.31px;
  gap: 18.16px;
  background-color: #3e7bdf;
  color: white;
  margin-top: 60px;
  border: 0;
  @media (max-width: 1169px) {
    margin-top: 30px;
    margin-left: 10px;
  }
  @media (max-width: 540px) {
    padding: 22px;
    font-size: 25px;
  }
`;

export const TextOuter = styled.div`
  margin-left: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1169px) {
    margin-top: 30px;
    margin-left: 0;
  }
`;
