import React from 'react';
import './App.css';
import logo from '../../assets/teamsimages/logo.png'
import bg1 from '../../assets/teamsimages/bggdsc3.png'
import bg2 from '../../assets/teamsimages/bggdsc4.png'
import bg3 from '../../assets/teamsimages/bggdsc.png'
import bg4 from '../../assets/teamsimages/bggdsc2.png'
import bg5 from '../../assets/teamsimages/bggdsc5.png'
import bg6 from '../../assets/teamsimages/bggdsc6.png'
import bg7 from '../../assets/teamsimages/bggdsc7.png'
import bg8 from '../../assets/teamsimages/bggdsc8.png'
import bg9 from '../../assets/teamsimages/bggdsc9.png'
import bg10 from '../../assets/teamsimages/bggdsc10.png'
import Card1 from './card1';
import Card2 from './card2';
import Card3 from './card3';
import Card4 from './card4';
import img1 from '../../assets/teamsimages/img1.png'
import img2 from '../../assets/teamsimages/img2.png'
import img3 from '../../assets/teamsimages/img3.png'
import img4 from '../../assets/teamsimages/img4.png'
import img5 from '../../assets/teamsimages/img5.png'
import img6 from '../../assets/teamsimages/img6.png'
import img7 from '../../assets/teamsimages/img7.png'
import img8 from '../../assets/teamsimages/img8.png'
import img9 from '../../assets/teamsimages/img9.png'
import img10 from '../../assets/teamsimages/img10.png'
import imga from '../../assets/teamsimages/imga.png'
import imgb from '../../assets/teamsimages/imgb.png'
import imgc from '../../assets/teamsimages/imgc.png'
import imgd from '../../assets/teamsimages/imgd.png'
import imge from '../../assets/teamsimages/imge.png'
import imgf from '../../assets/teamsimages/imgf.png'
import imgg from '../../assets/teamsimages/imgg.png'
import imgh from '../../assets/teamsimages/imgh.png'
import imgi from '../../assets/teamsimages/imgi.png'
import imgj from '../../assets/teamsimages/imgj.png'
import imgk from '../../assets/teamsimages/imgk.png'
import imgl from '../../assets/teamsimages/imgl.png'

const Header = () => {
    return (
      <div className="header-container">
        <div className="bg"></div>
        <div className="team-header">
          <span style={{ color: '#D63F33' }}>T</span>
          <span style={{ color: '#FBBC04' }}>E</span>
          <span style={{ color: '#3E7BDF' }}>A</span>
          <span style={{ color: '#34A853' }}>M</span>
        </div>
        <h1 className="second-header">GDSC SRM IST RMP</h1>
        <div className='circle'></div>
        <div className='circle2'></div>
        <div className='circle3'></div>
        <div className='circle4'></div>
        <img src={logo}alt='logo' className='logo'/>
        <p className='learn'>LEARN</p>
        <p className='connect'>CONNECT</p>
        <p className='grow'>GROW</p>
        <img src={bg1} alt='bg1' className='bg1'/>
        <img src={bg2} alt='bg2' className='bg2'/>
        <p className='core-team'>CORE TEAM</p>
        <p className='core-team-desc'>Step into the powerhouse of GDSC SRM Ramapuram!</p>
        <div className='core-team-container'>
            <>
            <Card1 className="team-card1" name ="DHRUV JHANG" position="GDSC LEAD"img={img1} insta="https://www.instagram.com/beingdhruv_2003/" link="https://www.linkedin.com/in/dhruv-jhang/" git="https://github.com/DhruvJhang" x="https://twitter.com/JhangDhruv"/>
            <Card2 className="team-card2" name ="" img={img2} insta="https://go.sarveshmrao.in/ig if you gonna tag then: https://go.sarveshmrao.in/igoffl" link="https://go.sarveshmrao.in/li" git="https://go.sarveshmrao.in/gh" x="https://go.sarveshmrao.in/x" />
            <Card3 className="team-card3" name ="" img={img3}insta="" link="" git="https://github.com/Ashwaaahere" x="" />
            <Card4 className="team-card4" name ="" img={img4}insta="https://www.instagram.com/ashwin_jahseh5" link="https://www.linkedin.com/in/ahn1305/" git="https://github.com/ahn1305" x="https://twitter.com/ahn1305" />
  
            <div className='row2'>
            <Card3 className="team-card3" name ="" img={img5} insta="https://www.instagram.com/praashhh._/" link="https://www.linkedin.com/in/prashanth-g-399225202/" git="https://github.com/prashx1908" x="https://twitter.com/Prashan14518598" />
            <Card1 className="team-card1" name ="" img={img6} insta="https://www.instagram.com/the._.pioneer/" link="http://www.linkedin.com/in/trusha-v-7a56b3255" git="https://github.com/Trusha2003/" x="https://x.com/v_trusha?t=YRosX0J-wkJvDxIlaUfVlg&s=09" />
            <Card4 className="team-card4" name ="" img={img7} insta="https://instagram.com/jayannthvenkat?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" link="https://www.linkedin.com/in/jayannth-venkat-91358b220?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" git="https://github.com/JayannthVenkat" x="" />
            <Card2 className="team-card2" name ="" img={img8}  insta="https://instagram.com/feat.srishti?igshid=OGQ5ZDc2ODk2ZA==" link="https://www.linkedin.com/in/srishti-singh-0a6b85248?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" git="https://github.com/Srishti1246" x="https://twitter.com/srishti_twts?t=W89IF6dIeh5w6TDc9MNnUA&s=08"/>
            <div className='row3'>
            <Card3 className="team-card3" name ="" img={img9} insta="https://instagram.com/0127_aryan?utm_source=qr&igshid=NGExMmI2YTkyZg==" link="https://www.linkedin.com/in/aryan-khandelwal-796a2521b/" git="https://github.com/0127aryan" x="https://twitter.com/AryanKh53383160"/>
            <Card2 className="team-card2" name ="" img={img10} insta="" link="" git="https://github.com/rrishika222" x="" />
            </div>
            </div>
            </>
        </div>
        <img src={bg3} alt='bg3' className='bg3'/>
        <img src={bg4} alt='bg4' className='bg4'/>
        <p className='technical-team'>TECHNICAL TEAM</p>
        <p className='technical-team-desc'>A cohort of tech maestros orchestrating innovations with precision.</p>
        <div className='technical-team-container'>
        <>
            <Card1 className="team-card1" name="" img={imga} insta="http://instagram.com/holding_heosphoros/" link="http://linkedin.com/in/harshita-ketharaman-a30a7022b" git="http://github.com/K-Harshita" x="" />
            <Card2 className="team-card2" name="" img={imgb} insta="http://www.instagram.com/nilsy_05/" link="https://www.linkedin.com/in/nilay06/" git="https://github.com/Sunset-06" x="" />
            <Card3 className="team-card3" name="" img={imgc} insta="" link="http://www.linkedin.com/in/mdsaquibdaiyan" git="https://github.com/saquib34/" x=""/>
            <Card4 className="team-card4"  name="" img={imgd}  link="https://www.linkedin.com/in/nayan-patidar-490aa8227?trk=contact-info" git="https://github.com/NayanPatidar" x="" />
            <Card3 className="team-card3" name="" img={imge} insta="https://www.instagram.com/shrey035/" link="https://www.linkedin.com/in/shreya-suresh-5b521726b/" git="https://github.com/shrey035" x="" />
            <Card1 className="team-card1"   name="" img={imgf} insta="https://www.instagram.com/hpriyanka_24/?hl=en" link="https://www.linkedin.com/in/priyankah24/" git="https://github.com/hpriyankaa" x=""/>
            <Card4 className="team-card4" name="" img={imgg} insta="https://www.instagram.com/___.gokull.___/" link="https://www.linkedin.com/in/gokul-r-97295628a/" git="https://github.com/FitzzyTre" x="https://twitter.com/SolveniteFr"/>
            <Card2 className="team-card2"  name="" img={imgh} insta="https://www.instagram.com/notkkdot/?hl=en" link="http://www.linkedin.com/in/kamalesh-khanna-s-001693296" git="https://github.com/codekkdot" x=""/>
            <Card2 className="team-card2" name="" img={imgi} insta="https://www.instagram.com/ridhideb_bose/" link="https://www.linkedin.com/in/ridhi-bose/" git="https://github.com/RidhiBose" x="https://twitter.com/ridhideb"/>
            <Card3 className="team-card3"  name="" img={imgj} insta="https://www.instagram.com/_ritzzzz._/" link="https://www.linkedin.com/in/riteshkumaran/" git="https://github.com/RiteshKumaran" x=""/>
            <Card1 className="team-card1"  name="" img={imgk} insta="https://www.instagram.com/mohamedarafath205/" link="https://www.linkedin.com/in/mohdarafath/" git="https://github.com/MohamedArafath205" x=""/>
            <Card4 className="team-card4"  name="" img={imgl} insta="https://instagram.com/_shreya_ig_" link="https://www.linkedin.com/in/-shreya-ig-" git="https://github.com/Shreya-9305" x="https://twitter.com/_ss_ig_" />
        </>
        </div>
        <img src={bg5} alt='bg5' className='bg5'/>
        <img src={bg6} alt='bg6' className='bg6'/>
        <p className='design-team'>DESIGN TEAM</p>
        <p className='design-team-desc'>A posse of design virtuosos crafting visual magic that captivates and inspires</p>
        <div className='design-team-container'>
        <>
            <Card1 className="team-card1" name="" img="" insta="" link="" git="" x=""/>
            <Card2 className="team-card2" name="" img="" insta="" link="" git="" x=""/>
            <Card3 className="team-card3" name="" img="" insta="" link="" git="" x=""/>
            <Card4 className="team-card4" name="" img=""  insta="" link="" git="" x=""/>
            <Card3 className="team-card3"  name="" img="" insta="" link="" git="" x=""/>
            <Card1 className="team-card1"  name="" img="" insta="" link="" git="" x="" />
            <Card4 className="team-card4" name=""  img="" insta="" link="" git="" x=""  />
            <Card2 className="team-card2"  name="" img="" insta="" link="" git="" x=""/>
            <Card4 className="team-card4" name="" img="" insta="" link="" git="" x="" />
            <Card2 className="team-card2" name="" img=""  insta="" link="" git="" x=""/>
            <Card3 className="team-card3" name="" img="" insta="" link="" git="" x=""/>
            <Card1 className="team-card1"  name="" img="" insta="" link="" git="" x=""/>
        </>
        </div>
        <img src={bg7} alt='bg7' className='bg7'/>
        <img src={bg8} alt='bg8' className='bg8'/>
        <p className='content-team'>CONTENT TEAM</p>
        <p className='content-team-desc'>Content mavens spinning tales that stick</p>
            <div className='content-team-container'>
            <Card1 className="team-card1" name=""  img="" insta="" link="" git="" x=""/>
            <Card2 className="team-card2" name="" img="" insta="" link="" git="" x=""/>
            <Card3 className="team-card3" name="" img="" insta="" link="" git="" x=""/>
            <Card4 className="team-card4"  name="" img=""  insta="" link="" git="" x=""/>
            </div>
            <img src={bg9} alt='bg9' className='bg9'/>
            <p className='management-team'>MANAGEMENT TEAM</p>
            <p className='management-team-desc'>Strategic minds steering the ship of success with precision and vision</p>
            <div className='management-team-container'>
            <Card1 className="team-card1" name="" img="" insta="" link="" git="" x=""/>
            <Card2 className="team-card2" name="" img="" insta="" link="" git="" x=""/>
            </div>
            <img src={bg10} alt='bg10' className='bg10'/>
            <p className='marketing-team'>MARKETING TEAM</p>
            <p className='marketing-team-desc'>Orchestrating campaigns that make waves and leave a lasting impact</p>
            <div className='marketing-team-container'>
            <Card3 className="team-card3" name="" img="" insta="" link="" git="" x=""/>
            <Card4 className="team-card4" name="" img="" insta="" link="" git="" x=""/>

              </div>


  
      </div>
    );
  };
  export default Header;
