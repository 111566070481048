import styled from "styled-components";

export const AnimatedText = styled.div`
  font-family: "Alata";
  position: relative;
  margin-left: 100px;
  display: block;
  margin-top: 100px;
  @media (max-width: 777px) {
    margin-left: 40px;
  }
  @media (max-width: 340px) {
    margin-left: 50px;
  }
  @media (max-width: 340px) {
    margin-left: 50px;
  }
`;

export const SloganTitle = styled.div`
  font-family: "Alata", sans-serif;
  font-weight: 400;
  line-height: 69px;
  font-size: 50px;
  background-image: linear-gradient(
    to right,
    rgba(95, 99, 104, 1),
    rgba(95, 99, 104, 0)
  );
  position: relative;
  margin-top: -20px;
  -webkit-background-clip: text;
  background-clip: text;
  margin-left: 40px;
  color: transparent;
  @media (max-width: 777px) {
    font-size: 35px;
  }
  @media (max-width: 600px) {
    background-image: linear-gradient(
      to right,
      rgba(95, 99, 104, 1),
      rgba(95, 99, 104, 0.44)
    );
  }
`;

export const Word = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.01s, visibility 0.4s, transform 0.4s;
  transform: translateY(50px);
  font-size: 100px;
  font-weight: 400;
  line-height: 136px;
  font-family: "Open Sans";
  margin-left: 31px;
  @media (max-width: 777px) {
    font-size: 60px;
    margin-top: -20px;
  }
  ${(props) =>
    props.isActive &&
    `
    opacity: 1;
  visibility: visible;
  transform: translateY(0);`}
  ${(props) =>
    props.isUsed &&
    `
    opacity: 0;
  visibility: hidden;
  transform: translateY(-50px);
  `}
`;
