import { Box } from "@mui/material";
import TestPage from "../components/testpage";

import React, { useEffect, useState } from "react";
import { db } from "../backend/firebase";
import { collection, doc, getDoc } from "firebase/firestore";

const App = () => {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const pages = collection(db, "pages");

      const aboutPage = doc(pages, "about");
      //console.log(pages);
      const aboutDataSnapshot = await getDoc(aboutPage);
      setAboutData(aboutDataSnapshot.data());
    };
    fetchData();
  }, []);

  if (!aboutData) return null;

  return (
    <Box>
      <TestPage aboutData={aboutData} />
    </Box>
  );
};

export default App;
