import React from 'react';
import { FaYoutube, FaInstagram, FaLinkedin, FaGithub, FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import * as Components from './footercomponents';
import RedImage from '../../assets/footer/red.png';
import BlueImage from '../../assets/footer/blue.png';
import YellowImage from '../../assets/footer/yellow.png';
import GreenImage  from '../../assets/footer/green.png';


const Footer = () => {
  return (
    <>
      <Components.StyledFooter>
      <Components.RedImage src={RedImage} alt="Red GDSC"/>
      <Components.SiteMap>SITE MAP</Components.SiteMap>
      <Components.Home>Home</Components.Home>
      <Components.About>About Us</Components.About>
      <Components.Events>Events</Components.Events>
      <Components.Connect>CONNECT</Components.Connect>
      <Components.Team>Team</Components.Team>
      <Components.Sponsor>Sponsors</Components.Sponsor>
      <Components.Blogs>Blogs</Components.Blogs>
      <Components.Contact>Contact Us</Components.Contact>
      <Components.BlueImage src={BlueImage} alt="Blue GDSC"/>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Components.LogoAlign>
            <Components.Red>G</Components.Red>
            <Components.Blue>D</Components.Blue>
            <Components.Green>S</Components.Green>
            <Components.Yellow>C</Components.Yellow>
          </Components.LogoAlign>
        </div>
        <Components.LogoText>SRMIST RAMAPURAM</Components.LogoText>
        <Components.Text>Join Our Community <br />to Get Further Updates</Components.Text>
        <Components.StyledButton variant="contained" color="primary">
          Join Dashboard
        </Components.StyledButton>
        <Components.Socials>Socials <i class="bi bi-arrow-right-circle" style={{fontWeight: 700}}></i> </Components.Socials>
        <Components.AlginIcons>
          <FaInstagram style={{background: 'linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7)', width: '30px', height: '30px', padding: '1px', borderRadius: '5px', margin: '5px'}} />
          <FaLinkedin style={{color: '#0A66C2', width: '30px', height: '30px', borderRadius: '5px', margin: '5px'}}/>
          <FaXTwitter style={{color: "000", width: '30px', height: '30px', borderRadius: '5px', margin: '5px'}}/>
          <FaYoutube style={{color: '#FF0000', width: '30px', height: '30px', borderRadius: '5px', margin: '5px'}}/>
          <FaGithub style={{color: '#181717', width: '30px', height: '30px', borderRadius: '5px', margin: '5px'}}/>
          <FaDiscord style={{background: '#7289DA', width: '30px', height: '30px', borderRadius: '5px', margin: '5px', padding: '1px'}}/>
        </Components.AlginIcons>
        <Components.YellowImage src={YellowImage} alt="Yellow GDSC"/>
        <Components.GreenImage src={GreenImage} alt="Green GDSC"/>
      </Components.StyledFooter>
    </>
  );
};

export default Footer;
