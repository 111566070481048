import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import logo from "./../../assets/Navbar/logo.png";
import DrawerComp from "./DrawerComp";

const PAGES = ["Home", "About", "Events", "Team", "Blogs", "Sponsor"];

const Header = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <React.Fragment>
        <AppBar sx={{ background: "white" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Logo */}
              <img src={logo} alt="GDSC-Logo" height="35" width="80" />
            </Box>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {!isMatch && (
                <Tabs
                  textcolor="7C8DB0"
                  value={value}
                  onChange={(e, value) => setValue(value)}
                  indicatorColor="#1E4978"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {PAGES.map((page, index) => (
                    <Tab key={index} label={page} />
                  ))}
                </Tabs>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="div" sx={{ ml: 2 }} />
              {!isMatch && <Button variant="contained">Contact Us</Button>}
              {isMatch && <DrawerComp />}
            </Box>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    </Box>
  );
};

export default Header;
