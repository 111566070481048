import React from 'react';
import TeamMembersPage from "../../components/team-members/App.jsx";


const TeamMembers = () => {
	return (
		<>
			<TeamMembersPage />
			
		</>
	);
};

export default TeamMembers;