import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import bluecircle from "../../assets/EventsPage/blue-circle.png";
import greencircle from "../../assets/EventsPage/green-circles.png";
import Triangle from "../../assets/EventsPage/triangle.png";
import redcircle from "../../assets/EventsPage/red-circles.png";
import eventsimage from "../../assets/EventsPage/event.jpg";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import HorizontalCard from "./HorizontalCard.jsx";
import CardGrid from "./VerticalCard.jsx";
import * as Components from "./eventspagecomponents";

function App() {
  const [count, setCount] = useState(0);
  const [apiData, setApiData] = useState({ results: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://gdsc.community.dev/api/event_slim/?chapter=833&page_size=100&status=Completed&include_cohosted_events=true&visible_on_parent_chapter_only=true&order=-start_date&fields=title,start_date,event_type_title,cropped_picture_url,cropped_banner_url,url,cohost_registration_url,description,description_short');
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <Box>
      <Components.MainImage src={eventsimage} />
      <Components.GreenCircle src={greencircle} />
      <Components.BlueCircle src={bluecircle} />
      <ScrollTrigger onEnter={() => setCount(1)} onExit={() => setCount(0)}>
        <Components.NumberCount>
          <div className="event-count">
            <Components.EventNumber>{count && <CountUp start={0} end={50} duration={2} delay={0} useGrouping={false} />} +</Components.EventNumber>
            <Components.EventText>Events</Components.EventText>
          </div>
          <div className="registration-count">
            <Components.RegNumber>{count && <CountUp start={0} end={1500} duration={2} delay={0} useGrouping={false} />} +</Components.RegNumber>
            <Components.RegText>Registrations</Components.RegText>
          </div>
          <div className="attendees-count">
            <Components.AtndNumber>{count && <CountUp start={0} end={1200} duration={2} delay={0} useGrouping={false} />} +</Components.AtndNumber>
            <Components.AtndText>Attendees</Components.AtndText>
          </div>
        </Components.NumberCount>
      </ScrollTrigger>
      <Components.Text1>Upcoming Events</Components.Text1>
      <HorizontalCard
        title="Innovate with Gen AI"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia quibusdam reiciendis earum! Aspernatur quidem alias earum deserunt obcaecati! Possimus, hic. Aspernatur quidem alias earum deserunt obcaecati! Possimus, hic. Aspernatur quidem alias earum deserunt obcaecati!"
        imageUrl="https://mdbcdn.b-cdn.net/wp-content/uploads/2020/06/vertical.webp"
        date="10th October 2021"
        time="10:00 AM"
        location="Online"
      />
      <Components.Text2>Previous Events</Components.Text2>
      <Components.TriangleImage src={Triangle} alt="TriangleImage" />
      <Components.RedCircle src={redcircle} alt="RedCircle" />
      {apiData.results && <CardGrid cardData={apiData} />}
    </Box>
  );
}

export default App;
