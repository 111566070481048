import React, { useState } from 'react';
import { Typography } from "@mui/material";
import styled from 'styled-components';

const Center = styled.div`
  margin-top: 123px;
`;

const GetInTouch = styled.div`
  color: black;
  font-family: 'Google Sans', sans-serif;
  margin-bottom: 50px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputField = styled.input`
  width: 550px;
  height: 55px;
  box-shadow: 0px 4px 21.6px rgb(128, 127, 127);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  border-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1vw;
  font-family: 'Open Sans', sans-serif;
  text-indent: 20px;
  margin-bottom: 30px;
  outline: none;
`;

const TextArea = styled.textarea`
  width: 550px;
  height: 255px;
  box-shadow: 0px 4px 21.6px rgb(128, 127, 127);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  border-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1vw;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 30px;
  text-indent: 20px;
  outline: none;
`;

const SubmitButton = styled.button`
  width: 150.24px;
  height: 63.24px;
  padding: 24.12px;
  border-color: rgb(255, 255, 255);
  background: #3E7BDF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24.12px;
`;

const Label = styled.label`
  color: white;
  font-size: 28.94px;
  font-family: 'Inter';
  font-weight: 600;
  word-wrap: break-word;
`;

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <Center>
      <FormContainer>
        <GetInTouch>
          <Typography variant="h2">
            Get In Touch
          </Typography>
        </GetInTouch>
        <form onSubmit={handleSubmit}>
          <InputField
            placeholder='Name'
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            id='name'
          />
          <br />
          <InputField
            placeholder='Email'
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            id='email'
          />
          <br />
          <TextArea
            placeholder='Type your message here'
            name="message"
            value={formData.message}
            onChange={handleChange}
            id='message'
          />
          <br />
          <SubmitButton type="submit">
            <Label>Submit</Label>
          </SubmitButton>
        </form>
      </FormContainer>
    </Center>
  );
};

export default ContactUsForm;
