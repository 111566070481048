// import './AboutUs.css';
import AboutPage from '../../components/aboutus/aboutus';
import Footer from '../../components/footer/footer';


import { Box } from '@mui/material';
import React, { useEffect, useState } from "react";
import { db } from "../../backend/firebase";
import { collection, doc, getDoc } from "firebase/firestore";
const Aboutus = () => {
    const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const pages = collection(db, "pages");

      const aboutPage = doc(pages, "about");
      //console.log(pages);
      const aboutDataSnapshot = await getDoc(aboutPage);
      setAboutData(aboutDataSnapshot.data());
    };
    fetchData();
  }, []);

  if (!aboutData) return null;

  return (
    <Box>
      <AboutPage aboutData={aboutData} />
        <Footer />
    </Box>

  );};

export default Aboutus;
