import Insta from "../../assets/teamsimages/insta.png"
import Linkedin from "../../assets/teamsimages/linkddd.png"
import Github from "../../assets/teamsimages/github.png"
import X from "../../assets/teamsimages/x.png"



function Card (props){
    return (
    
    <div className="team-card4">
            <img src={props.img} alt="profile-pic" className="image"></img>
            <p className="card-title">{props.name}</p>
            <p className="position">{props.position}</p>
            <div className="social-media-container">
            <a href={props.insta}><img alt="insta" src={Insta} className="insta"></img></a>
            <a href={props.link}><img alt="linkedin" src={Linkedin} className="linkedin"></img></a>
            <a href={props.git}><img alt="git" src={Github} className="git"></img></a>
            <a href={props.x}><img alt="x" src={X} className="x"></img></a>
            </div>
        </div>
        );
    }
export default Card