import styled from "styled-components";

export const CircleItem = styled.div`
  border-radius: 50%;
  width: 114px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-family: sans-serif;
  margin: 0 10px;
  background-color: #ea4335;
`;
