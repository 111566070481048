import CodeLogo from "../../assets/sponsors/Sponsor-circle.png";
import SponsorsCard from "../../components/Sponsors/SponsorsCard";
import Circles from "../../components/Sponsors/Circles";
import topLeftBg from "../../assets/sponsors/top-left-bg.svg";
import topRightBg from "../../assets/sponsors/top-right-bg.svg";
import bottomLeftBg from "../../assets/sponsors/bottom-left-bg.svg";
import bottomRightBg from "../../assets/sponsors/bottom-right-bg.svg";
import * as Components from "../../components/Sponsors/SponsorsComponent";
import React, { useEffect, useState } from "react";
import NavBar from '../../components/Navbar/Header';
import { db } from "../../backend/firebase";
import { collection, doc, getDoc } from "firebase/firestore";

const SponsorsPage = () => {
  const [fetchedData, setFetchedData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const sponsors = collection(db, "sponsors");
      let data = {};

      const documentNames = ["bevy", "github", "google"];

      for (const docName of documentNames) {
        const docRef = doc(sponsors, docName);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          data[docName] = docSnapshot.data();
        } else {
          console.log(`No data found for ${docName}`);
        }
      }

      setFetchedData(data);
    };

    fetchData();
  }, []);

  if (Object.keys(fetchedData).length === 0) return null;
  console.log(fetchedData);
  console.log(fetchedData["google"]["url"]);

  const sponsors = [
    {
      img: fetchedData["google"]["img-url"],
      url: fetchedData["google"]["url"],
      alt: "Google",
    },
    {
      img: fetchedData["bevy"]["img-url"],
      url: fetchedData["bevy"]["url"],
      alt: "bevy",
    },
    {
      img: fetchedData["github"]["img-url"],
      url: fetchedData["github"]["url"],
      alt: "github",
    },
  ];
  const circles = [
    {
      content: <img src={CodeLogo} alt="Red Circle" />,
      bgColor: "#EA4335",
    },
    {
      content: <span>Learn</span>,
      bgColor: "#F9AB00",
    },
    {
      content: <span>Connect</span>,
      bgColor: "#34A853",
    },
    {
      content: <span>Grow</span>,
      bgColor: "#4285F4",
    },
  ];

  return (
    <Components.Sponsor>
      <NavBar />
      <Components.Header>SPONSORS</Components.Header>
      <Components.HomeCircles>
        {circles.map((item, i) => (
          <Circles
            content={item.content}
            key={i}
            bgColor={item.bgColor}
          ></Circles>
        ))}
      </Components.HomeCircles>

      <SponsorsCard sponsor={sponsors} />

      <Components.TopLeftBg src={topLeftBg} alt="" />
      <Components.BottomLeftBg src={bottomLeftBg} alt="" />
      <Components.TopRightBg src={topRightBg} alt="" />
      <Components.BottomRightBg src={bottomRightBg} alt="" />
    </Components.Sponsor>
  );
};

export default SponsorsPage;
