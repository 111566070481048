import React from 'react'
import './Aboutus.css'
import { Box } from '@mui/material';
import { Button } from '@mui/material';

function AboutPage({ aboutData }) {
    return (
        <Box>
        <div className="Aboutus">
        <div className="super-bg">

            <div className="main-bg">
                <div className="part1">
                    <div className="main-text">
                        <h1>GDSC</h1>
                        <h3>SRM INSTITUTE OF SCIENCE AND TECHNOLOGY, RAMAPURAM</h3>
                    </div>
                </div>
                <div className="bc1">
                    <div className="triangle2"></div>
                </div>
                <div className="part2">
                    <div className="main-text">
                        <h1>{aboutData["red-title"]}</h1>
                        <p>{aboutData["red-desc"]}</p>
                    </div>
                    <div className="img1"></div>
                </div>
                <div className="bc2">
                    <div className="triangle2"></div>
                </div>
                <div className="part3">
                    <div className="img2"></div>
                    <div className="main-text">
                        <p>{aboutData["yellow-desc"]}</p>
                    </div>
                </div>
                <div className="bc3">
                    <div className="triangle2"></div>
                </div>
                <div className="part4">
                    <h1>{aboutData["green-title"]}</h1>
                    <div className="container">
                        <div className="main-text">
                            <p>{aboutData["green-desc"]}</p>
                        </div>
                        <div className="img3"></div>
                    </div>
                </div>
                <div className="bc4">
                    <div className="triangle2"></div>
                </div>
                <div className="part5">
                    <Box className="drop" marginBottom={'100px'}>
                    <div class="dropdown" >
                        <Button class="dropbtn">DOMAINS ▼</Button>
                        <div class="dropdown-content">
                            <a href="#">TECHNICAL</a>
                            <a href="#">DESIGN</a>
                            <a href="#">CONTENT</a>
                            <a href="#">MANAGEMENT</a>
                        </div>
                    </div>
                    </Box>
                </div>
            </div>
        </div>
    </div>

        </Box>
    )
}

export default AboutPage;
