import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import tinycolor from 'tinycolor2';
import { FaCalendarAlt } from 'react-icons/fa';

function stripHtmlTags(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

function VerticalCard({ text, imageUrl, color, date, eventUrl }) {
  const parsedDate = new Date(date);
  const formattedDate = parsedDate.toISOString().split('T')[0];
  const strippedText = stripHtmlTags(text);

  const adjustedColor = tinycolor(color).lighten(35).toString();
  const dateColor = tinycolor(color).lighten(0).toString();

  const handleDetailsClick = () => {
    window.open(eventUrl, '_blank');
  };

  const [cardWidth, setCardWidth] = useState('25rem');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 375) {
        setCardWidth('20rem');
      } else if (window.innerWidth <= 450) {
        setCardWidth('24rem');
      } else {
        setCardWidth('25rem');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card style={{ width: {cardWidth}, height: '650px', margin: '10px 0', display: 'flex', flexDirection: 'column', backgroundColor: adjustedColor, border: `2px solid ${dateColor}`, borderRadius: '14px' }}>
      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <Card.Img variant="top" src={imageUrl} style={{ height: '350px', objectFit: 'cover', width: '100%', borderRadius: '5px', marginBottom: '-5px' }} />
          <Container style={{ backgroundColor: dateColor, textAlign: 'center', padding: '7px', borderRadius: '5px', marginBottom: '3px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FaCalendarAlt style={{ marginRight: '5px' }} /> {formattedDate}
          </Container>
          <Card.Text style={{
            marginTop: '10px',
            textAlign: 'justify',
            textJustify: 'inter-word',
            textAlignLast: 'center',
            maxHeight: '190px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 7,
          }}>
            {strippedText}
          </Card.Text>
        </div>
        <Button variant="primary" onClick={handleDetailsClick} style={{ width: '100%', borderRadius: '20px', marginTop: 'auto', backgroundColor: dateColor, border: dateColor }}>
          Details <i className="bi bi-arrow-right"></i>
        </Button>
      </Card.Body>
    </Card>
  );
}

function CardGrid({ cardData }) {
  const [visibleCards, setVisibleCards] = useState(9);

  if (!cardData || !cardData.results) {
    return null;
  }

  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 3);
  };

  const cardGridStyle = {
    marginTop: window.innerWidth <= 450 ? '180px' : '0',
  };  

  return (
    <Container style={cardGridStyle}>
      <Row>
        {cardData.results.slice(0, visibleCards).map((event, index) => (
          <Col key={index} md={4}>
            <VerticalCard
              text={event.description}
              imageUrl={event.cropped_picture_url}
              color={getCardColor(index)}
              date={event.start_date}
              eventUrl={event.url} 
            />
          </Col>
        ))}
      </Row>
      {visibleCards < cardData.results.length && (
        <Row>
          <Col className="text-center mt-4">
            <Button variant="primary" onClick={handleLoadMore} style={{ background: 'rgba(0, 0, 0, 0.22)', border: '1px solid rgba(0, 0, 0, 0.22)', width: '250px', color: 'black' }}>
              Load more Events  <i class="bi bi-chevron-double-down"></i>
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}

function getCardColor(index) {
  const colors = ['#FBBC04', '#329A4E', '#D63F33', '#3E7BDF'];
  return colors[index % colors.length];
}

export default CardGrid;