import React from "react";
import styled from 'styled-components';
import Grid from '@mui/material/Unstable_Grid2';
import ContactUsForm from './contactUsForm';
import ContactUsFormMobile from "./mobileContact";
import ContentImage from '../../assets/contactUs/Content.png';
import graphicRight from '../../assets/contactUs/graphicRight.png';

const Container = styled.div`
  position: relative;
  @media (max-width: 1199px) {
    /* Styles for the div when the device width is less than 1200 pixels */
    display: none;
  }
`;

const LeftContent = styled.div`
  position: fixed;
  border-radius: 75px;
  border: 1.5px black solid;
  background-repeat: no-repeat;
`;

const Rectangle2 = styled(LeftContent)`
  width: 368px;
  height: 385px;
  left: -303px;
  top: 0;
  position: fixed;
  background-repeat: no-repeat;

`;

const Rectangle1 = styled(LeftContent)`
  width: 368px;
  height: 385px;
  left: -213px;
  top: 101px;
  position: fixed;
  background-repeat: no-repeat;

`;

const Ellipse1 = styled.div`
  position: absolute;
  width: 200px;
  height: 100px;
  left: 0px;
  top: 0px;
  background: #EA4335;
  border-radius: 0 0 100px 100px;
  transform: rotate(230deg);
  margin-top: 201.5px;
  margin-bottom: 178.09px;
  margin-left: -30px;
  z-index: -1;
  position: fixed;
  background-repeat: no-repeat;

  
`;

const Vector1 = styled.div`
  position: absolute;
  width: 376.76px;
  height: 345.20px;
  margin-top: 627.68px;
  border: 4px #ffffff solid;
  background-image: url(${graphicRight});
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  
`;

const MiddleContent = styled.div`
  margin-left: 450px;
  position: absolute;
  display: flex;

`;

const ImageContainer = styled.div`
  top: 0px;
  background-image: url(${ContentImage});
  background-size: contain;
  right: -306px;
  height: 100vh;
  width: 50vw;
  background-repeat: no-repeat;
  position: fixed;
  z-index: -1;
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 1199px) {
    /* Styles for the div when the device width is less than 1200 pixels */
    display: block;
  }
`;

const App = () => {
  return (
    <>
    <Container className="App">
      <Grid container  spacing={3} >
        <div></div>
        <Grid item  md={12} lg={3} xl={0.5}>
          <LeftContent>
            <Rectangle2></Rectangle2>
            <Rectangle1></Rectangle1>
            <Ellipse1></Ellipse1>
            <Vector1></Vector1>
          </LeftContent>
        </Grid>

        <Grid item md={12} lg={5} xl={9}>
          <MiddleContent >
            <ContactUsForm />
          </MiddleContent>
        </Grid>

        <Grid item  md={12} lg={4} xl={2.5}>
          <div id="rightContent">
            <ImageContainer ></ImageContainer>
          </div>
        </Grid>
      </Grid>
      </Container>


      <MobileContainer className="mobileContainer">
        <div className="formMobile">
          <ContactUsFormMobile />
        </div>
      </MobileContainer>
    </>
  );
}

export default App;
