import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledFooter = styled.footer`
    background: linear-gradient(to right, #FFFFFF, #C5C5C5);
    color: #fff;
    padding: 2rem 0;
    text-align: center;
    font-size: 1.5rem;
    position: relative;
    bottom: 0;
    width: 100%;
    min-height: 400px;
    left: 0;
    right: 0;
    margin-top: 2rem;
    font-family: 'Google Sans Display', sans-serif;

    @media screen and (max-width: 376px){
        height: 1020px;
        width: 100vw;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        height: 1020px;
        width: 100vw;
    }

    @media screen and (min-width: 451px) and (max-width: 820px){
        height: 1000px;
        width: 100vw;
    }
    @media screen and (min-width: 821px) and (max-width: 1024px){
        height: 600px;
        width: 100vw;
    }
`;

export const Green = styled.h1`
    color: rgba(45, 182, 82, 1);
    font-weight: 700;
    padding: 0 10px;
    font-size: 100px;

    @media screen and (max-width: 376px){
        position: relative;
        left: -6.6em;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        position: relative;
        left: -6.4em;
    }
    
    @media screen and (min-width: 451px) and (max-width: 820px){
        position: relative;
        left: -4.7em;
    }
    
    @media screen and (min-width: 821px) and (max-width: 1024px){
        position: relative;
        top: 0em;
    }
`;

export const Red = styled.h1`
    color: rgba(234, 67, 53, 1);
    font-weight: 700;
    padding: 0 10px;
    font-size: 100px;

    @media screen and (max-width: 376px){
        position: relative;
        left: -6.2em;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        position: relative;
        left: -6em;
    }
        
    @media screen and (min-width: 451px) and (max-width: 820px){
        position: relative;
        left: -4.3em;
    }
        
    @media screen and (min-width: 821px) and (max-width: 1024px){
        position: relative;
        top: 0em
    }
`;

export const Blue = styled.h1`
    color: rgba(62, 123, 223, 1);
    font-weight: 700;
    padding: 0 10px;
    font-size: 100px;

    @media screen and (max-width: 376px){
        position: relative;
        left: -6.4em;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        position: relative;
        left: -6.2em;
    }
        
    @media screen and (min-width: 451px) and (max-width: 820px){
        position: relative;
        left: -4.5em;
    }
        
    @media screen and (min-width: 821px) and (max-width: 1024px){
        position: relative;
        top: 0em;
    }
`;

export const Yellow = styled.h1`
    color: rgba(249, 171, 0, 1);
    font-weight: 700;
    padding: 0 10px;
    font-size: 100px;

    @media screen and (max-width: 376px){
        position: relative;
        left: -6.8em;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        position: relative;
        left: -6.6em;
    }
    
    @media screen and (min-width: 451px) and (max-width: 820px){
        position: relative;
        left: -4.9em;
    }
    
    @media screen and (min-width: 821px) and (max-width: 1024px){
        position: relative;
        top: 0em;
    }
`;

export const LogoText = styled.h1`
    position: absolute;
    left: 660px;
    top: 240px;
    color: rgba(105, 99, 99, 1);
    font-weight: 700;
    font-size: 30px;

    @media screen and (max-width: 376px){
        left:25px;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        left:35px;  
    }
    
    @media screen and (min-width: 451px) and (max-width: 820px){
        left:210px;  
    }
    
    @media screen and (min-width: 821px) and (max-width: 1024px){
        top: 8em;  
    }
`;

export const RedImage = styled.img`
    position: absolute;
    width: 10%;
    height: 40%;
    left: 0;
    top: 0;
    @media screen and (max-width: 376px){
        top: 0px;
        height: 10%;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        top: 0px;
        height: 10%;
    }
    
    @media screen and (min-width: 451px) and (max-width: 820px){
        top: 0px;
        height: 8%;
    }
    
    @media screen and (min-width: 821px) and (max-width: 1024px){
        top: 0px;
        height: 10%;
    }
`;

export const BlueImage = styled.img`
    position: absolute;
    width: 23%;
    height: 40%;
    right: 0;
    top: 0;

    @media screen and (max-width: 376px){
        top: 0px;
        height: 10%;
    }
    
    @media screen and (min-width: 377px) and (max-width: 450px){
        top: 0px;
        height: 10%;
    }
        
    @media screen and (min-width: 451px) and (max-width: 820px){
        top: 0px;
        height: 8%;
    }
        
    @media screen and (min-width: 821px) and (max-width: 1024px){
        top: 0px;
        height: 10%;
    }
`;

export const YellowImage = styled.img`
    position: absolute;
    width: 23%;
    height: 40%;
    left: 0;
    bottom: 0;

    @media screen and (max-width: 376px){
        bottom: 0px;
        height: 10%;
    }

    @media screen and (min-width: 377px) and (max-width: 450px){
        bottom: 0px;
        height: 10%;
    }
    
    @media screen and (min-width: 451px) and (max-width: 820px){
        bottom: 0px;
        height: 10%;
    }
    
    @media screen and (min-width: 821px) and (max-width: 1024px){
        bottom: 0px;
        height: 10%;
    }
`;

export const GreenImage = styled.img`
    position: absolute;
    width: 15%;
    height: 40%;
    right: 0;
    bottom: 0;

    @media screen and (max-width: 376px){
        bottom: 0px;
        height: 10%;
    }

    
    @media screen and (min-width: 377px) and (max-width: 450px){
        height: 10%;
    }
        
    @media screen and (min-width: 451px) and (max-width: 820px){
        height: 10%;
    }
        
    @media screen and (min-width: 821px) and (max-width: 1024px){
        height: 10%;
    }
`;

export const SiteMap = styled.div`
    position: absolute;
    top: 47px;
    left: 188px;
    color: black;
    font-weight: 800;
    font-size: 20px;

  @media screen and (max-width:376px){
        top: 20em;
        left: 180px;
        margin-left: -7em;
    }  

    @media screen and (min-width: 377px) and (max-width:450px){
        top: 20em;
        left: 180px;
        margin-left: -7em;
    } 
    
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 20em;
        left: 300px;
        margin-left: -7em;
    } 
    
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 8em;
        left: 230px;
        margin-left: -7em;
    } 
`;

export const Home = styled.div`
    position: absolute;
    top: 115px;
    font-weight: 700;
    left: 188px;
    color: black;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 23em;
        left: 180px;
        margin-left: -7em;
    }

    @media screen and (min-width: 377px) and (max-width:450px){
        top: 23em;
        left: 180px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 23em;
        left: 300px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 821px) and (max-width: 1024px){
        top: 11em;
        left: 230px;
        margin-left: -7em;
    }
`;

export const About = styled.div`
    position: absolute;
    top: 183px;
    font-weight: 700;
    left: 188px;
    color: black;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 26em;
        left: 180px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 26em;
        left: 180px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 26em;
        left: 300px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width: 1024px){
        top: 14em;
        left: 230px;
        margin-left: -7em;
    }
`;

export const Events = styled.div`
    position: absolute;
    top: 251px;
    font-weight: 700;
    left: 188px;
    color: black;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 29em;
        left: 180px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 29em;
        left: 180px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 29em;
        left: 300px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width: 1024px){
        top: 17em;
        left: 230px;
        margin-left: -7em;
    }
`;

export const LogoAlign = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    top: 105px;
    left: 638px;
`;

export const Connect = styled.div`
    position: absolute;
    top: 47px;
    left: 368px;
    color: black;
    font-weight: 800;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 20em;
        left: 350px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 20em;
        left: 350px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 20em;
        left: 600px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 8em;
        left: 500px;
        margin-left: -7em;
    }
`;

export const Team = styled.div`
    position: absolute;
    top: 115px;
    font-weight: 700;
    left: 368px;
    color: black;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 23em;
        left: 350px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 23em;
        left: 350px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 23em;
        left: 600px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 11em;
        left: 500px;
        margin-left: -7em;
    }
`;

export const Sponsor = styled.div`
    position: absolute;
    top: 163px;
    font-weight: 700;
    left: 368px;
    color: black;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 26em;
        left: 350px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 26em;
        left: 350px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 26em;
        left: 600px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 14em;
        left: 500px;
        margin-left: -7em;
    }
`;

export const Blogs = styled.div`
    position: absolute;
    top: 211px;
    font-weight: 700;
    left: 368px;
    color: black;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 29em;
        left: 350px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 29em;
        left: 350px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 29em;
        left: 600px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 17em;
        left: 500px;
        margin-left: -7em;
    }
`;

export const Contact = styled.div`
    position: absolute;
    top: 259px;
    font-weight: 700;
    left: 368px;
    color: black;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 32em;
        left: 350px;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and  (max-width:450px){
        top: 32em;
        left: 350px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and  (max-width:820px){
        top: 32em;
        left: 600px;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and  (max-width:1024px){
        top: 20em;
        left: 500px;
        margin-left: -7em;
    }
`;

export const Text = styled.div`
    position: absolute;
    top: 170px;
    left: 1130px;
    color: black;
    font-weight: 700;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 37em;
        left: 11em;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 37em;
        left: 11em;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 37em;
        left: 20em;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 15em;
        left: 42em;
        margin-left: -6em;
    }
`;

export const StyledButton = styled(Button)`
    position: absolute;
    top: 210px;
    left: 480px;

    @media screen and (max-width:376px){
        top: 55em;
        left: 0em;
        margin-left: -7em;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 55em;
        left: -1em;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 55em;
        left: -3em;
        margin-left: -7em;
    }
        
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 24em;
        left: 23em;
        margin-left: -7em;
    }
`;

export const Socials = styled.div`
    position: absolute;
    bottom: 10px;
    left: 848px;
    color: black;
    font-weight: 700;
    font-size: 20px;

    @media screen and (max-width:376px){
        top: 45em;
        left: 0em;
        padding-left: 3px;
    }
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 45em;
        left: 0em;
        padding-left: 3px;
    }
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 45em;
        left: 9em;
        padding-left: 3px;
    }
        
    @media screen and (min-width: 821px) and (max-width:1024px){
        top: 24em;
        left: 5em;
        padding-left: 3px;
    }
`;

export const AlginIcons = styled.div`
    position: absolute;
    bottom: 6px;
    left: 960px;

    @media screen and (max-width:376px){
        top: 37.2em;
        left: 4em;
        padding-left: 3px;
    } 
    
    @media screen and (min-width: 377px) and (max-width:450px){
        top: 37.2em;
        left: 4em;
        padding-left: 3px;
    }  
        
    @media screen and (min-width: 451px) and (max-width:820px){
        top: 37.2em;
        left: 12em;
        padding-left: 3px;
    } 
        
    @media screen and (min-width: 821px) and (max-width: 1024px){
        top: 19.7em;
        left: 9em;
        padding-left: 3px;
    }   
`;