import styled, { createGlobalStyle } from "styled-components";

export const Home = styled.div`
  margin: 0;
  position: relative;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 600px) {
    overflow: auto;
  }
`;

export const Google = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 35px;
  z-index: -5;
  @media (max-width: 600px) {
    margin-bottom: 25px;
  }
`;

export const GoogleImg = styled.img`
  @media (max-width: 600px) {
    width: 325px;
  }
`;

export const MainHeader = styled.h1`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: "Alata", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 55.2px;
  color: #5f6368;
  @media (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 35px;
  }
`;

export const SubHeader = styled.h1`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  color: #7b8897;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 4px;
  word-wrap: break-word;
  @media (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 35px;
    margin-top: 35px;
  }
`;

export const TopLeftBg = styled.img`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50px;
  width: 100;
  @media (max-width: 1108px) {
    height: 240px;
  }
  @media (max-width: 880px) {
    display: none;
  }
`;

export const BottomRightBg = styled.img`
  position: absolute;
  right: 0;
  bottom: 0px;
  @media (max-height: 1366px) {
    display: none;
  }
  @media (max-width: 1108px) {
    bottom: -30px;
    height: 400px;
  }
  @media (max-height: 1000px) {
    display: block;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const MiddleLines = styled.img`
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  @media (max-width: 1108px) {
    bottom: -40%;
    height: 900px;
    top: 10px;
  }
`;
