import styled from 'styled-components';

export const GreenCircle = styled.img`
    float: right;
    position: absolute;
    width: 50%;
    right: 0;
    top: 5px;
  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 798px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;

export const BlueCircle = styled.img`
    float: left;
    position: relative;
    width: 50%;
  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 798px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;

export const TriangleImage = styled.img`
    float: right;
    position: absolute;
    right: 0;
    top: 42em;
    width: 25%;
  @media screen and (max-width: 1200px) {
    top: 42em;
    width: 25%;
  }
  @media screen and (max-width: 1100px) {
    top: 36em;
    width: 25%;
  }
  @media screen and (max-width: 1000px) {
    top: 36em;
    width: 25%;
  }
  @media screen and (max-width: 798px) {
    top: 32em;
    width: 25%;
  }
  @media screen and (max-width: 600px) {
    top: 26em;
    width: 25%;
  }
  @media screen and (max-width: 480px) {
    top: 18em;
    width: 25%;
  }
`;

export const MainImage = styled.img`
    position: relative;
    z-index: 1;
    display: block;
    border-radius: 15px;
    width: 1300px;
    height: 380px;
    margin-top: 170px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1200px) {
      width: 100%;
      height: 380px;
    }
`;
export const NumberCount = styled.div`
  font-family: 'Product Sans', sans-serif;
  position: absolute;
  z-index: 2;
  background: #C5C5C5;
  border-radius: 15px;
  border: 2px solid #676C72;
  width: 49%;
  height: 22.5%;
  top: 61%;
  left: 25%;

  @media screen and (max-width: 1280px) {
    top: 60%;
  }

  @media screen and (max-width: 1200px) {
    top: 60%;
  }

  @media screen and (max-width: 1025px){
    top: 35%;
    height: 10.5%
  }

  @media screen and (max-width: 450px){
    top: 56%;
    width: 80%;
    height: 10%;
    left: 10%;
  }

  @media screen and (max-width: 400px){
    top: 60%;
  }

  @media screen and (max-width: 376px) {
    width: 80%;
    height: 10%;
    top: 77%;
    left: 10%;
  }

  @media screen and (max-width: 361px){
    top: 69%;
  }
`;

export const EventNumber = styled.div`
  font-family: 'Product Sans', sans-serif;
  position: absolute;
  left: 80px;
  display: inline-block;
  bottom: 65px;
  font-size: 3rem;
  font-weight: bold;

  @media screen and (max-width: 1280px) {
    left: 50px;
    bottom: 70px;
    font-size: 3rem;
  }

  @media screen and (max-width: 1200px) {
    left: 40px;
    bottom: 70px;
    font-size: 3rem;
  }

  @media screen and (max-width: 1150px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 1050px){
    left: 35px;
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    left: 50px;
    bottom: 60px;
    font-size: 1rem;
  }

  @media screen and (max-width: 450px){
    left: 30px;
    bottom: 45px;
    font-size: 1rem;
  }

  @media screen and (max-width: 376px){
    left: 30px;
    bottom: 30px;
    font-size: 1rem;
  }
`;

export const EventText = styled.div`
  font-family: 'Product Sans', sans-serif;
  position: absolute;
  left: 70px;
  display: inline-block;
  bottom: 30px;
  font-size: 2rem;
  font-weight: bold;

  
  @media screen and (max-width: 1280px) {
    left: 48px;
    bottom: 40px;
    font-size: 2rem;
  }

  @media screen and (max-width: 1200px) {
    left: 38px;
    bottom: 40px;
    font-size: 2rem;
  }

  @media screen and (max-width: 1150px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1050px){
    left: 35px;
    font-size: 1.3rem;
  }

  @media screen and (max-width: 768px) {
    left: 50px;
    bottom: 25px;
    font-size: 1rem;
  }

  @media screen and (max-width: 450px){
    left: 20px;
    bottom: 20px;
    font-size: 1rem;
  }

  @media screen and (max-width: 376px){
    left: 20px;
    bottom: 10px;
    font-size: 1rem;
  }
`;

export const RegNumber = styled.div`
  font-family: 'Product Sans', sans-serif;
  position: absolute;
  left: 280px;
  display: inline-block;
  bottom: 65px;
  font-size: 3rem;
  font-weight: bold;

  @media screen and (max-width: 1280px) {
    left: 220px;
    bottom: 70px;
    font-size: 3rem;
  }

  @media screen and (max-width: 1200px) {
    left: 200px;
    bottom: 70px;
    font-size: 3rem;
  }

  @media screen and (max-width: 1050px){
    left: 180px;
    font-size: 2rem;
  }

  @media screen and (max-width: 1050px){
    left: 180px;
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    left: 180px;
    bottom: 60px;
    font-size: 1rem;
  }

  @media screen and (max-width: 450px){
    left: 125px;
    bottom: 45px;
    font-size: 1rem;
  }

  @media screen and (max-width: 376px){
    left: 115px;
    bottom: 30px;
    font-size: 1rem;
  }
`;

export const RegText = styled.div`
  font-family: 'Product Sans', sans-serif;  
  position: absolute;
  left: 250px;
  display: inline-block;
  bottom: 30px;
  font-size: 2rem;
  font-weight: bold;

  @media screen and (max-width: 1280px) {
    left: 190px;
    bottom: 40px;
    font-size: 2rem;
  }

  @media screen and (max-width: 1200px) {
    left: 170px;
    bottom: 40px;
    font-size: 2rem;
  }

  @media screen and (max-width: 1150px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1050px){
    left: 150px;
  }

  @media screen and (max-width: 768px) {
    left: 180px;
    bottom: 25px;
    font-size: 1rem;
  }

  @media screen and (max-width: 450px){
    left: 100px;
    bottom: 20px;
    font-size: 1rem;
  }

  @media screen and (max-width: 376px){
    left: 90px;
    bottom: 10px;
    font-size: 1rem;
  }
`;

export const AtndNumber = styled.div`
  font-family: 'Product Sans', sans-serif;
  position: absolute;
  left: 520px;
  display: inline-block;
  bottom: 65px;
  font-size: 3rem;
  font-weight: bold;

  @media screen and (max-width: 1280px) {
    left: 430px;
    bottom: 70px;
    font-size: 3rem;
  }

  @media screen and (max-width: 1200px) {
    left: 410px;
    bottom: 70px;
    font-size: 3rem;
  }

  @media screen and (max-width: 1150px) {
    left: 380px;
    font-size: 2.5rem;
  }

  @media screen and (max-width: 1050px){
    font-size: 2rem;
    left: 350px;
  }

  @media screen and (max-width: 768px) {
    left: 350px;
    bottom: 60px;
    font-size: 1rem;
  }

  @media screen and (max-width: 450px){
    left: 240px;
    bottom: 45px;
    font-size: 1rem;
  }

  @media screen and (max-width: 376px){
    left: 220px;
    bottom: 30px;
    font-size: 1rem;
  }
`;

export const AtndText = styled.div` 
  font-family: 'Product Sans', sans-serif;
  position: absolute;
  left: 520px;
  display: inline-block;
  bottom: 30px;
  font-size: 2rem;
  font-weight: bold;

  @media screen and (max-width: 1280px) {
    left: 430px;
    bottom: 40px;
    font-size: 2rem;
  }

  @media screen and (max-width: 1200px) {
    left: 410px;
    bottom: 40px;
    font-size: 2rem;
  }

  @media screen and (max-width: 1150px) {
    left: 380px;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1050px){
    left: 340px;
  }

  @media screen and (max-width: 768px) {
    left: 350px;
    bottom: 25px;
    font-size: 1rem;
  }

  @media screen and (max-width: 450px){
    left: 225px;
    bottom: 20px;
    font-size: 1rem;
  }

  @media screen and (max-width: 376px){
    left: 210px;
    bottom: 10px;
    font-size: 1rem;
  }

  @media screen and (max-width: 361px){
    left: 205px;
  }
`;

export const RedCircle = styled.img`
  position: absolute;
  top: 75em;
  left: 0;
`;

export const Text1 = styled.h2`
  position: absolute;
  top: 20em;
  font-weight: 700;
  font-size: 36px;
  left: 8%;
  color: #676C72;
  font-family: 'Product Sans', sans-serif;

  @media screen and (max-width: 450px){
    top: 18em;
  }
`;

export const Text2 = styled.h2`
  position: absolute;
  top: 34em;
  font-weight: 700;
  font-size: 36px;
  left: 8%;
  color: #676C72;
  font-family: 'Product Sans', sans-serif;

  @media screen and (max-width: 450px){
    top: 40em;
  }

  @media screen and (max-width: 376px){
    top: 42em;
  }
`;
