import React, { useState, useEffect } from "react";
import * as Components from "./SloganComponent";

const words = [
  {
    text: "Learn",
    color: "#EA4335",
  },
  {
    text: "Connect",
    color: "#F9AB00",
  },
  {
    text: "Grow",
    color: "#34A853",
  },
  {
    text: "Learn",
    color: "#4285F4",
  },
  {
    text: "Connect",
    color: "#EA4335",
  },
  {
    text: "Grow",
    color: "#34A853",
  },
];
const SloganAnimation = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Components.AnimatedText>
      <Components.SloganTitle> A PLACE TO</Components.SloganTitle>
      {words.map((word, index) => (
        <Components.Word
          key={index}
          isActive={index === currentWordIndex}
          isUsed={index < currentWordIndex}
          style={{
            color: word.color,
          }}
        >
          {word.text}
        </Components.Word>
      ))}
    </Components.AnimatedText>
  );
};

export default SloganAnimation;
