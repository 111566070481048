import React, { useState } from 'react';
import { Typography } from "@mui/material";
import styled from 'styled-components';
import mobileDisplayImage from '../../assets/contactUs/mobileDisplay.png'; // Import the image
import useMediaQuery from '@mui/material/useMediaQuery';

const MCenter = styled.div`
  text-align: center;
  margin: 0 auto;
  box-shadow: 0px 4px 21.6px rgb(255, 255, 255);
  background-image: url(${mobileDisplayImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  border-color: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  position: absolute;
`;

const MGetInTouch = styled.div`
  color: black;
  font-family: 'Google Sans', sans-serif;
  margin-top: 30px;
  padding-bottom: 20px;
`;

const MForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MInputField = styled.input`
  width: 70%;
  height: 45px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 4px 21.6px rgb(128, 127, 127);
  border-radius: 25px;
  border-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.5);
  font-size: 4vw;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 30px;
  text-indent: 20px;
  outline: none;
  padding-bottom: 0px;
  margin-bottom: 5px;
`;

const MTextArea = styled.textarea`
  width: 70%;
  height: 255px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 4px 21.6px rgb(128, 127, 127);
  border-radius: 25px;
  border-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.5);
  font-size: 4vw;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 30px;
  text-indent: 20px;
  outline: none;
`;


const SubmitButton = styled.button`
  width: 150.24px;
  height: 63.24px;
  padding: 24.12px;
  border-color: rgb(255, 255, 255);
  background: #3E7BDF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24.12px;
`;
const Label = styled.label`
  color: white;
  font-size: 28.94px;
  font-family: 'Inter';
  font-weight: 600;
  word-wrap: break-word;
`;
const ContactUsFormMobile = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
  };
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const variant = isSmallScreen ? 'h6' : 'h4';
  return (
    <MCenter>
      <MForm onSubmit={handleSubmit}>
        <MGetInTouch>
          <Typography variant={variant}>
            Get In Touch
          </Typography>
        </MGetInTouch>
        <MInputField
          placeholder='Name'
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          id='Mname'
        />
        <br />
        <MInputField
          placeholder='Email'
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          id='Memail'
        />
        <br />
        <MTextArea
          placeholder='Type your message here'
          name="message"
          value={formData.message}
          onChange={handleChange}
          id='Mmessage'
        />
        <br />
        <SubmitButton type="submit">
            <Label>Submit</Label>
          </SubmitButton>
      </MForm>
    </MCenter>
  );
};

export default ContactUsFormMobile;
